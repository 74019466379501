/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

@font-face {
  font-family: SansationLight;
  src: url(static/Sansation_Light-webfont.woff);
}

@font-face {
  font-family: SansationRegular;
  src: url(static/Sansation_Regular-webfont.woff);
}

.ReactModal__Overlay
{
  background-color: white !important;
  background-image: url(static/HaLongSomewhatThin.png) !important;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}

.ReactModal__Content
{
  border: none !important;
  background-color: rgba(0,0,0,0) !important;

}

.modal-page
{
  font-family: arial;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;

}

.modal-content
{
  width: 480px;
  height: 300px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #f4f5ff !important;
  border-color: #c2e9f6;
  border-style: solid;
  border-width: 1px;
}

.modal-content h1
{
  font-family: SansationLight;
  font-style:italic;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.modal-content button
{
  margin:3px;
  background-color: #d1ddff;
  border-radius: 10px;
  padding:8px;
  width: 80px;

  border-color: #d0dcff;
  border-style: solid;
  border-width: 1px;
}

.page-wrapper
{
  background: #F1F1F1 none repeat scroll 0 0;
  padding: 0 0px;
  width: 100%;
  display: flex;
}

.page
{
  margin: auto;
  margin-top: 20px;
  width: 870px;
  background-color: white;
}

.page .content
{
  margin:0px;
  background-color: white;
}


.header .title
{
  color: rgb(51, 51, 51);
  font-family: "SansationLight", "Helvetica Neue", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-size: 30px;
  font-style: italic;
  font-weight: 700;
  line-height: 36px;
  margin: 30px;

}

.search-tag
{
  background-color: rgb(228, 237, 251);
  border-radius: 12px;
  border-color: #c5c5f2;
  border-width: 1px;
  border-style: solid;
  padding: 3px;
  font-size: 13px;
  font-family: arial;
  margin: 3px;
  height: 20px;

  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}




.search-tag .close
{
  color: #1d3b77;
  font-size:14px;
}

.search
{
  font-family: "arial";
  font-size:12px;
  /*margin: 25px;*/
  /*padding-left: 30px;*/
}



.search .time-range
{
  padding: 10px;
}

.ui-input
{
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  font-size:12px;
  text-align: center;
  padding: 3px;
  margin: 2px;
  font-family: arial;
}

.personal-data .contact-container
{
  line-height: 2em;
}

.contact-container .ui-input
{
  background-color: #eee7f7;
  color: black;
  border-style: solid;
  border-color: #ced1f1;
}

.contact-container .ui-input:visited
{
  text-decoration: none;
}

.contact-container .ui-input:link
{
  text-decoration: none;
}

.search input[type=text]
{
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  font-size:12px;
  text-align: center;
  border-color: #d2eaff;

  padding-left: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 8px;
  height: 20px;
}

.search input[type=text]:focus
{
  text-align: left;
  border-color: #c5c5f2;
  outline: none;
}


.search-tag .text
{
  font-size:12px;
}
.search-tags
{
  height: 25px;
}

.search-tags input[type=text]
{
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  font-size:12px;
  text-align: center;
  margin-left: 40px;

  padding-left: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 8px;
  height: 20px;
}

.search-tags input[type=text]:focus
{
  text-align: left;
  border-color: #c5c5f2;
  outline: none;
}


.header .image
{
  background-image: url(static/HaLongSomewhatThin.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100px;
}

.footer
{
  border-top-style: solid;
  border-top-width: 3px;
  border-top-color: black;
  width: 100%;
}
.footer > .contact-container
{
  margin: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 15px;
}

.footer > .image
{
  background-image: url(static/endOfPageSmall.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: 50px;
}


.resume
{
  display: flex
}

.resume .search
{
  padding-left: 60px;
  padding-top: 30px;
  padding-bottom: 10px;
  /*background-image: linear-gradient(180deg, #dce3f1, white, white,   white);*/
  background-color: #f3f5fa;
}

.resume-content
{
  overflow-y: auto;
  height: 500px;
  /*background-image: linear-gradient(180deg, #eff6fd, white, white, white, white, white, white, white, white, white, white, white, white, white, white);*/
  /*border-top-left-radius: 50px;*/
}

.resume-information
{
  margin-top: 30px;
  margin-left: 60px;
}

.section
{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.search > .section-title
{
  font-size: 16px;
  margin-bottom: 10px;
}

.resume-information > .section > .section-title
{
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 17px;
  margin-left: 0px;
  margin-top: 27px;
}

.resume-information > .section > .section-title, .search > .section-title
{
  display: flex;
  font-family: SansationRegular;
  color: rgb(51, 51, 51);
  font-style: italic;

  overflow-wrap: break-word;

}

.section-body
{
  display: flex;

  border-collapse: collapse;
  color: rgb(51, 51, 51);
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  font-size: 12px;
  line-height: 21.6px;
  overflow-wrap: break-word;
  margin-left: 15px;

  flex-direction: column;
  flex-basis: 100%;

}

.toggle a:link
{
  color: #0066CC;
  text-decoration: none;
}

.toggle a
{
  font-size: 12px;
  font-family: "SansationLight", "Helvetica Neue", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-style: italic;
}

.toggle a:visited
{
  color: #0066CC;
  text-decoration: none;

}

.toggle-full
{
  font-size: 20px;
}

.resume
{
  display: flex;
  flex-direction: column;
}

.section-information
{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.personal-data > .section-information
{
  width: 650px;

}
.personal-data .section-information-row
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: 100%;
}
.personal-data .section-information-row:nth-child(odd)
{
  background-image: linear-gradient(to right, #f3f5fa, white); /*linear-gradient(173deg, #eff6fd, #fafcff, white)*/
  border-radius: 7px;
}

.personal-data .section-information-property
{
  flex-grow: 1;
  flex-basis: 150px;
  margin-left: 10px;
}

.personal-data .section-information-value
{

  flex-grow: 1;
  flex-basis: 200px;
}


.major.projects .project .section-title
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.major.projects .project .section-title .section-information-row
{
  display: flex;
  flex-direction: row;
  width: 700px;
  justify-content: flex-start;
  margin-top: 4px;
  margin-bottom: 3px;
}

.major.projects .project .section-title .section-information-row .section-information-property
{
  justify-content: flex-start;
  width: 350px;
  margin-left:10px;
}

.major.projects .project .section-title .section-information-row .section-information-value
{
  justify-content: flex-start;
  width: 300px;
}

.major.projects > .section-body > .projects > .project:nth-child(odd) .section-title
{
  background-image: linear-gradient(to right, #f3f5fa, white); /*linear-gradient(173deg, #eff6fd, #fafcff, white)*/
  border-radius: 7px;
}

.experience .section-information-row
{
  display: flex;
  flex-direction: row;
}

.experience .projects > .section-title
{
  display: flex;
  flex-direction: row;
}
.experience .projects > .section-title > .section-information-row
{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;
  margin-top: 4px;
  margin-bottom: 3px
}
.experience .projects > .section-title > .section-information-row > .section-information-property
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 650px;
  margin-left: 8px;
}

.experience .projects.table-header > .section-title
{
  font-weight: bold;
}


.experience .projects > .section-title > .section-information-row > .section-information-property > .activity
{
  width: 350px;
}

.experience .projects > .section-title > .section-information-row > .section-information-property > .organisation
{
  width: 200px;
}


.experience .projects > .section-title > .section-information-row > .section-information-value
{
  justify-content: flex-start;
  width: 180px;
}


.experience .projects:nth-child(odd) > .section-title > .section-information-row
{
  background-image: linear-gradient(to right, #f3f5fa, white); /*linear-gradient(173deg, #eff6fd, #fafcff, white)*/
  border-radius: 7px;
}


.experience .project .section-title .section-information-row .section-information-property
{
  justify-content: flex-start;
  width: 200px;
  margin-left:10px;
}

.experience .project .section-title .section-information-row .section-information-value
{
  justify-content: flex-start;
  width: 300px;
}

.experience .projects  .project > .section-title
{
  display: flex;
  flex-direction: row;
}

.experience .projects .project:nth-child(odd) > .section-title
{
  background-image: linear-gradient(to right, #f3f5fa, white); /*linear-gradient(173deg, #eff6fd, #fafcff, white)*/
  border-radius: 7px;
}


.experience .projects  .project > .section-title > .section-information-row
{
  width: 90%;
}
.experience .projects > .section-body > .context
{
  width: 550px
}

.project-body
{
  margin-left: 10px;
  /*border-style: solid;*/
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left-width: 7px;
  padding-left: 17px;
  border-color: #b1d6f7;
  width: 550px;
}

.project-sub-section-title
{
  font-weight: bold;
}

.project.section > .section-title
{
  width: 100%;
}

.project.section > .section-title > .section-information-row
{
  width: 300px;
  margin: 0px;
}

.skill-set .list
{
  margin-left: 20px;
  margin-bottom: 10px;
  /*font-family: arial;*/
}

.skill-set > *
{
  text-transform: capitalize;
}

.skill-set > .category
{
  font-weight: bold;
}